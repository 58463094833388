import React, { useState, useEffect, useRef, useCallback } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import QRCode from "react-qr-code";
import Webcam from "react-webcam";

const Account = () => {
  const apiUrl = config.ENDPOINT;
  const triggerUrl = config.TRIGGERURL;
  const triggerAuth = config.TRIGGERAUTH;

  const [ userInfo, setUserInfo ] = useState({});
  const [ user, setUser ] = useState({});
  const [ photo, setPhoto ] = useState(null);
  const [ captured, setCaptured ] = useState(false);
  const [ qrcode, setQrcode ] = useState({});
  const [ showQr, setShowQr ] = useState(false);
  const [ showFace, setShowFace ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  
  const camRef = useRef(null);
  const capture = useCallback(() => {
    const image = camRef.current.getScreenshot({width: 720, height: 720});
    setPhoto(image);
    setCaptured(true);
  }, [camRef]);
  const reset = () => {
    setPhoto(null);
    setCaptured(false);
  }
  
  const randomKey = () => {
    const chars = '1234567890abcdefghijklmnopqrstuvwxyz';
    const digits = 16;
    let result = '';
    for (let i = 0; i < digits; i++) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getUser();
    }
  }, [userInfo]);

  const getUser = async () => {
    const fetchUser = await fetch(apiUrl + 'get/user', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userInfo.userId
      })
    });
    const dataUser = await fetchUser.json();
    if (dataUser.status === 200) {
      if (dataUser.values.face_key) {
        const readUrl = await read(dataUser.values.face_key);
        setPhoto(readUrl);
      }
      if (dataUser.values.qrcode_key) {
        const readQr = extract(dataUser.values.qrcode_key);
        console.log(readQr);
        setQrcode(readQr);
      }
      setUser(dataUser.values);
    }
  }
  
  const editUserAccess = async (fileName) => {
    const fetchUserAccess = await fetch(apiUrl + 'edit/user/access', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userInfo.userId,
        accessType: "Face",
        accessKey: fileName,
        status: 'Pending'
      })
    });
    const dataUserAccess = await fetchUserAccess.json();
    console.log(dataUserAccess);
    if (dataUserAccess.status === 200) {
      console.log(dataUserAccess);
    }
  }

  const sendEmail = async () => {
    const fetchEmail = await fetch(apiUrl + 'email', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userInfo.userId,
        userName: userInfo.name,
        userEmail: userInfo.email,
        accessType: 'Face',
        emailType: 'Pending Access',
        emailTo: 'Admin',
      })
    });
    const dataEmail = await fetchEmail.json();
    if (dataEmail.status === 200) {
      console.log(dataEmail);
    }
  }
  
  const sendTrigger = async (fileName) => {
    const fetchTrigger = await fetch(triggerUrl, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": triggerAuth,
      },
      method: "POST",
			body: JSON.stringify({
        action: "Add",
        filename: fileName,
        bucket_name: "door_access_ps",
        bucket_path: "image-face-recognition-final/" + fileName
      })
    });
    const dataTrigger = await fetchTrigger.json();
    if (dataTrigger.error === false) {
      return true;
    } else {
      return false;
    }
  }

  const upload = async () => {
    setLoading(true);
    setError('');
    const fileName = randomKey() + '.jpg';
    const fetchUrl = await fetch(apiUrl + 'file/upload', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userInfo.userId,
        fileName: fileName
      })
    });
    const dataUrl = await fetchUrl.json();
    if (dataUrl.status === 200) {
      const atob = require('atob');
      const base64Data = photo.replace(/^data:image\/\w+;base64,/, '');
      const binaryData = atob(base64Data);
      const len = binaryData.length;
      const uint8Array = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: 'image/jpeg' });
      await fetch(dataUrl.values, {
        headers: {
          "Content-Type": "application/octet-stream"
        },
        method: "PUT",
        body: blob
      });
      const dataTrigger = await sendTrigger(fileName);
      if (dataTrigger) {
        await editUserAccess(fileName);
        await sendEmail();
        window.location.reload();
      } else {
        reset();
        setLoading(false);
        setError('Face is not detected. Please try again.');
        console.log('Error uploading face');
      }
    } else {
      reset();
      setLoading(false);
      setError('Error uploading face. Please try again.');
      console.log('Error getting signed URL');
    }
  }

  const read = async (fileName) => {
    const fetchUrl = await fetch(apiUrl + 'file/read', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userInfo.userId,
        fileName: fileName,
      })
    });
    const dataUrl = await fetchUrl.json();
    if (dataUrl.status === 200) {
      return dataUrl.values;
    } else {
      console.log(dataUrl);
    }
  }

  const extract = (qrcodeString) => {
    const [qrcodeKey, qrcodeDatetime] = qrcodeString.split('_');
    const qrcodeExpiry = moment(qrcodeDatetime, 'YYYYMMDDHHmmss');
    const qrcodeStatus = qrcodeExpiry.isSameOrAfter(moment()) ? 'Active' : 'Expired';
    return {
      string: qrcodeString,
      key: qrcodeKey,
      expiry: qrcodeExpiry.format('YYYY-MM-DD HH:mm:ss'),
      status: qrcodeStatus
    };
  }

  return (
    <div id="user-account">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'My Account'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <h3 className="mb-4">Account Information</h3>
            <div className="card">
              <div className="card-body p-4">
                <div className="">
                  <div className="row mt-2">
                    <div className="col-md-2"><strong>Name</strong></div>
                    <div className="col-md-10">{user.name}</div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-2"><strong>Email</strong></div>
                    <div className="col-md-10">{user.email}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              {((user.qrcode_status === "Active") && (user.qrcode_key))  ? (
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body p-4">
                      <h4><i className="bi bi-qr-code me-2"/> QR Code</h4>
                      <button className="btn btn-sm btn-primary w-100 mt-2" onClick={() => setShowQr(true)}>Show</button>
                    </div>
                  </div>
                </div>
              ) : ''}
              {user.face_status !== "Disabled" ? (
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body p-4">
                      <h4><i className="bi bi-person-bounding-box me-2"/> Face</h4>
                      {user.face_key ? (
                        user.face_status === 'Active' ? (
                          <button className="btn btn-sm btn-primary w-100 mt-2" onClick={() => setShowFace(true)}>Reset</button>
                        ) : (
                          <button className="btn btn-sm btn-warning w-100 mt-2" onClick={() => setShowFace(true)}>Pending</button>
                        )
                      ) : (
                        <button className="btn btn-sm btn-primary w-100 mt-2" onClick={() => setShowFace(true)}>Register</button>
                      )}
                    </div>
                  </div>
                </div>
              ) : ''}
            </div>
          </div>
        </div>
      </div>

      <Modal size="md" show={showQr} onHide={() => setShowQr(false)}>
        <Modal.Header closeButton>
          <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {qrcode ? (
            <div className="d-flex flex-column align-items-center">
              <QRCode value={qrcode.string}/>
              <p className="mt-2 mb-0">{qrcode.string}</p>
              {qrcode.status === 'Expired' ? (<p className="text-center text-danger mb-0">QR Code expired, request a new one</p>) : ''}
            </div>
          ) : ''}
        </Modal.Body>
        <Modal.Footer>
          {qrcode && qrcode.status === 'Expired' ? (
            <button className="btn btn-primary me-2" disabled={loading}>{loading ? 'Requesting...' : 'Request'}</button>
          ) : ('')}
          <span onClick={() => setShowQr(false)}>Close</span>
        </Modal.Footer>
      </Modal>

      <Modal size="md" show={showFace} onHide={() => setShowFace(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Face</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            {photo ? (
              <div>
                <img src={photo} width={400} height={400} alt="Captured" style={{objectFit: 'cover'}} />
              </div>
            ) : (
              <div style={{position: 'relative'}}>
                <img src="/face.png" alt="Face" style={{position: 'absolute', zIndex: 2, objectFit: 'cover', width: '100%'}}/>
                <Webcam className="camera" width={400} height={400} ref={camRef} style={{objectFit: 'cover'}} mirrored={true} screenshotFormat="image/jpeg" videoConstraints={{width: 400, height: 400, facingMode: "user"}}/>
                {error ? (<p className="text-center text-danger mb-0">{error}</p>) : ''}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {captured ? (
            <div>
              <button className="btn btn-secondary bg-grey me-2" onClick={() => reset()}><i className="bi bi-arrow-counterclockwise"/></button>
              <button className="btn btn-primary" onClick={() => upload()} disabled={loading}>{loading ? 'Uploading...' : 'Upload'}</button>
            </div>
          ) : (
            photo ? (
              <button className="btn btn-primary" onClick={() => reset()}>Reset</button>
            ) : (
              <button className="btn btn-primary" onClick={() => capture()}>Capture</button>              
            )
          )}          
          <span onClick={() => setShowFace(false)}>Close</span>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Account;
