import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal } from "react-bootstrap";

const PendingFace = () => {
  const apiUrl = config.ENDPOINT;

  const [ userInfo, setUserInfo ] = useState({});
  const [ pendingNotification, setPendingNotification ] = useState([]);
  const [ users, setUsers ] = useState([]);
  const [ userAccess, setUserAccess ] = useState({});
  const [ photo, setPhoto ] = useState('');
  const [ showDetail, setShowDetail ] = useState(false);
  const [ loadingAssign, setLoadingAssign ] = useState(false);

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getPendingUsers();
      getPendingNotification();
    }
  }, [userInfo]);

  const getPendingUsers = async () => {
    const fetchUsers = await fetch(apiUrl + 'pending/face', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataUsers = await fetchUsers.json();
    if (dataUsers.status === 200) {
      setUsers(dataUsers.values);
    }
  }

  const getPendingNotification = async () => {    
    const fetchPendingNotification = await fetch(apiUrl + 'pending/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
      })
    });
    const dataPendingNotification = await fetchPendingNotification.json();
    if (dataPendingNotification.status === 200) {
      setPendingNotification(dataPendingNotification.values);
    }
  }

  const getPhoto = async (dataUser) => {
    const fetchUrl = await fetch(apiUrl + 'file/read', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userInfo.userId,
        fileName: dataUser.access_key,
      })
    });
    const dataUrl = await fetchUrl.json();
    if (dataUrl.status === 200) {
      setPhoto(dataUrl.values);
      return dataUrl.values;
    } else {
      console.log(dataUrl);
    }
  }
  
  const assignFace = async (status) => {
    setLoadingAssign(true);
    const fetchUserAccess = await fetch(apiUrl + 'edit/user/access', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userAccess.user_id,
        accessType: "Face",
        accessKey: (status === true ? userAccess.access_key : ''),
        status: "Active"
      })
    });
    await fetchUserAccess.json();
    await sendEmail(status);
    window.location.reload();
  }

  const sendEmail = async (status) => {
    const fetchEmail = await fetch(apiUrl + 'email', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userAccess.user_id,
        userName: userAccess.user_name,
        userEmail: userAccess.user_email,
        accessType: 'Face',
        emailType: (status === true ? 'Approved Access' : 'Rejected Access'),
        emailTo: 'User',
      })
    });
    const dataEmail = await fetchEmail.json();
    if (dataEmail.status === 200) {
      console.log(dataEmail);
    }
  }

  const handleDetailAccess = (userAccess) => {
    setUserAccess(userAccess);
    getPhoto(userAccess);
    setShowDetail(true);
  }

  return (
    <div id="user-groups">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Access'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <h3 className="mb-4">Access List</h3>
            <ul className="nav nav-tabs mb-4">
              <li className="nav-item">
                <a className="nav-link active" href="/pending/face">Pending Face {users.length ? (<span className="circle ms-3">{users.length}</span>) : ''}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/pending/rfid">Pending RFID {pendingNotification.length ? (<span className="circle ms-3">{pendingNotification.length}</span>) : ''}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/logs">Access Log</a>
              </li>
            </ul>
            <div className="table-responsive">
              {users.length > 0 ? (
                <table className="table table-border table-hover">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Access Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, u) => (
                      <tr className="pointer" key={'access-log-' + u} onClick={() => handleDetailAccess(user)}>
                        <td>{user.user_name}</td>
                        <td>{user.access_type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : ('No Data')}
            </div>
          </div>
        </div>
      </div>

      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Photo Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="detail" onSubmit={(e) => e.preventDefault()}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>User</label></div>
              <div className="col-md-8">{userAccess.user_name}</div>
            </div>
            <img className="w-100 rounded mt-3" src={photo}/>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="">
              <span className="text-danger ms-2" onClick={() => assignFace(false)} disabled={loadingAssign}>Reject</span>
            </div>
            <div className="d-flex align-items-center">
              <span onClick={() => setShowDetail(false)}>Close</span>
              <button className="btn btn-primary ms-3" onClick={() => assignFace(true)} disabled={loadingAssign}>{loadingAssign ? "Loading..." : "Approve"}</button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PendingFace;
